import { PluginObject } from "vue/types/plugin";
import Vue from "vue";
import axios from "axios";
import { httpClientFactory } from "@/services/http/http";

export const AxiosPlugin: PluginObject<{ [key: string]: string }> = {
  install(vue: typeof Vue): void {
    vue.prototype.$http = axios.create();
    vue.prototype.$httpClientFactory = httpClientFactory;
  },
};
