import _Vue from "vue";
import {
  AadAuthService,
  IMsalPluginConfig,
  useAad,
} from "@/services/auth/aadAuthService";
import { PluginObject } from "vue/types/plugin";

export const AadPlugin: PluginObject<IMsalPluginConfig> = {
  install(Vue: typeof _Vue, options: IMsalPluginConfig | undefined): void {
    if (options) {
      const instance: AadAuthService = useAad(options);
      Vue.prototype.$auth = instance;
      Vue.prototype.$isAuthenticated = instance.isAuthenticated.bind(instance);
    }
  },
};
