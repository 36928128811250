import { NavigationClient, NavigationOptions } from "@azure/msal-browser";
import VueRouter from "vue-router";

export default class VueRouterNavigationClient extends NavigationClient {
  private router: VueRouter;

  constructor(router: VueRouter) {
    super();
    this.router = router;
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useHistory hook provided by react-router-dom to take advantage of client-side routing
   * @param url
   * @param options
   */
  async navigateInternal(
    url: string,
    options: NavigationOptions
  ): Promise<boolean> {
    const relativePath = url.replace(window.location.origin, "");
    if (options.noHistory) {
      await this.router.replace(relativePath);
    } else {
      await this.router.push(relativePath);
    }

    return false;
  }
}
