















import Vue from "vue";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import AppPage from "@/components/layout/AppPage.vue";
import AppLoadingSpinner from "@/components/indicators/AppLoadingSpinner.vue";

export default Vue.extend({
  name: "AppPageLoading",
  components: { AppLoadingSpinner, AppPage, AppPageBanner },
  props: {
    title: {
      type: String,
      default: "Loading...",
    },
  },
  data: () => ({}),
});
