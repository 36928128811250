import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  WebinarsAdminClient,
  Agenda,
  IWebinarSettingsAdminResponse,
  SpeakersAdminResponse,
  WebinarAvailability,
  WebinarFullDetailsAdminResponse,
  WebinarParticipantAdminResponse,
  WebinarProviderConfigAdminResponse,
  WebinarStatus,
  WebinarUpdatedAdminResponse,
  WebinarUpdateDetailsRequest,
} from "@/clients/clients";
import { getAuthInstance } from "@/services/auth/aadAuthService";
import { loginRequest } from "@/configs/authConfig";
import { httpClientFactory } from "@/services/http/http";

interface IWebinarStoreState {
  settings: IWebinarSettingsAdminResponse;
  webinar: WebinarFullDetailsAdminResponse | null;
}

const state: IWebinarStoreState = {
  settings: {
    speakers: [],
    tags: [],
  },
  webinar: null,
};

const getters: GetterTree<IWebinarStoreState, any> = {
  id: (state: IWebinarStoreState): string => state.webinar?.id ?? "",
  settings: (state: IWebinarStoreState): IWebinarSettingsAdminResponse =>
    state.settings,
  speakers: (state: IWebinarStoreState): SpeakersAdminResponse[] =>
    state.webinar?.speakers ?? [],
  agendas: (state: IWebinarStoreState): Agenda[] =>
    state.webinar?.agendas ?? [],
  participants: (
    state: IWebinarStoreState
  ): WebinarParticipantAdminResponse[] => state.webinar?.participants ?? [],
  providerConfig: (
    state: IWebinarStoreState
  ): WebinarProviderConfigAdminResponse | null =>
    state.webinar?.providerConfig ?? null,
  availability: (state: IWebinarStoreState): WebinarAvailability | null =>
    state.webinar?.availability ?? null,
  status: (state: IWebinarStoreState): WebinarStatus | null =>
    state.webinar?.status ?? null,
  webinar: (
    state: IWebinarStoreState
  ): WebinarFullDetailsAdminResponse | null => state.webinar,
};

const mutations: MutationTree<IWebinarStoreState> = {
  updateSettings(
    state: IWebinarStoreState,
    payload: IWebinarSettingsAdminResponse
  ): void {
    state.settings = Object.assign({}, state.settings, payload);
  },
  setWebinar(
    state: IWebinarStoreState,
    payload: WebinarFullDetailsAdminResponse
  ): void {
    state.webinar = Object.assign({}, state.settings, payload);
  },
  reset(state: IWebinarStoreState) {
    state.settings = {
      speakers: [],
      tags: [],
    };
    state.webinar = null;
  },
};

const actions: ActionTree<IWebinarStoreState, any> = {
  async getSettings({ commit }): Promise<IWebinarSettingsAdminResponse> {
    const auth = getAuthInstance();
    const tokenRequest = await auth?.authClient.acquireTokenSilent(
      loginRequest
    );
    const accessToken = tokenRequest?.accessToken;
    const client = httpClientFactory(WebinarsAdminClient, accessToken);
    const settings = await client.getSettings();
    commit("updateSettings", settings);
    return settings;
  },
  async getWebinar(
    { commit },
    id: string
  ): Promise<WebinarFullDetailsAdminResponse> {
    const auth = getAuthInstance();
    const tokenRequest = await auth?.authClient.acquireTokenSilent(
      loginRequest
    );
    const accessToken = tokenRequest?.accessToken;
    const client = httpClientFactory(WebinarsAdminClient, accessToken);
    const settings = await client.getWebinar(id);
    commit("setWebinar", settings);
    return settings;
  },

  async updateWebinarDetails(
    context,
    payload: WebinarUpdateDetailsRequest
  ): Promise<WebinarUpdatedAdminResponse> {
    const auth = getAuthInstance();
    const tokenRequest = await auth?.authClient.acquireTokenSilent(
      loginRequest
    );
    const accessToken = tokenRequest?.accessToken;
    const client = httpClientFactory(WebinarsAdminClient, accessToken);
    return await client.updateWebinarDetails(payload.id ?? "", payload);
  },

  reset({ commit }): void {
    commit("reset");
  },
};

const WebinarStore: Module<IWebinarStoreState, any> = {
  namespaced: true,
  state: () => state,
  mutations,
  actions,
  getters,
};

export default WebinarStore;
