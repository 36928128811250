























import Vue from "vue";

export default Vue.extend({
  name: "AppPageBanner",
  data: () => ({}),
  props: {
    title: {
      type: String,
      default: "",
    },
  },
});
