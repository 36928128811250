























































































import Vue from "vue";
import { loginRequest } from "@/configs/authConfig";
import { mapGetters } from "vuex";
import { AdminUserProfileResponse, UserRoleType } from "@/clients/clients";

export default Vue.extend({
  name: "TheNavigationDrawerMenu",
  data: () => ({}),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    loggedIn(): boolean {
      return this.$auth.authenticated;
    },
    ...mapGetters(["account"]),
    adminRoles(): typeof UserRoleType {
      return UserRoleType;
    },
  },
  methods: {
    async login(): Promise<void> {
      await this.$auth.loginRedirect(loginRequest);
    },
    async logout(): Promise<void> {
      await this.$auth.logout();
    },
    canAccess(role: UserRoleType): boolean {
      return (
        (this.account as AdminUserProfileResponse).adminRoles?.some(
          (p) => p == UserRoleType.GlobalAdmin || p == role
        ) ?? false
      );
    },
  },
});
