import axios, { AxiosInstance } from "axios";

const BaseUrl = process.env.VUE_APP_API_URL;

export function httpClientFactory<T>(
  client: { new (baseUrl?: string, instance?: AxiosInstance): T },
  token?: string
): T {
  const headers: { [key: string]: string } = {};
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  const axiosClient = axios.create({ headers: headers });
  return new client(BaseUrl, axiosClient);
}
