






import Vue from "vue";
import AppPageLoading from "@/components/layout/AppPageLoading.vue";
import { loginRequest } from "@/configs/authConfig";
import { UsersAdminClient } from "@/clients/clients";
import { mapMutations } from "vuex";

export default Vue.extend({
  name: "LoginView",
  components: { AppPageLoading },
  data: () => ({
    error: "",
  }),
  async created() {
    try {
      const redirect = await this.$auth.handleRedirectPromise();
      if (redirect) {
        this.$auth.handleRedirect();
        const token = await this.$auth.acquireTokenSilent(loginRequest);
        const client = this.$httpClientFactory(
          UsersAdminClient,
          token.accessToken
        );
        const account = await client.getAdminAccount();
        this.updateAccount(account);
        await this.$router.push({ name: "Home" });
      } else {
        await this.$router.push({ name: "Unauthorised" });
      }
    } catch (e) {
      this.error = e;
      await this.$router.push({ name: "Unauthorised" });
    }
  },
  methods: {
    ...mapMutations(["updateAccount"]),
  },
});
