



































import Vue, { PropType } from "vue";
import { Enquiry, EnquiryType } from "@/clients/clients";
import { DataOptions, DataTableHeader } from "vuetify";
import enquiryTypeLabelMapper from "@/providers/enquiryTypeLabelMapper";

export default Vue.extend({
  name: "AppEnquiriesTable",
  data: () => ({
    headers: [
      { text: "Enquiry Number", value: "enquiryNumber" },
      { text: "Source", value: "source" },
      { text: "Email", value: "email" },
      { text: "No. Items", value: "numberOfItems", sortable: false },
      { text: "Enquiry Types", value: "enquiryTypes" },
      { text: "Status", value: "enquiryStatus" },
      { text: "Requested", value: "createdAt" },
      { text: "Updated", value: "updatedAt" },
      { text: "Update", value: "update", sortable: false },
    ],
    tableOptions: {} as DataOptions,
  }),
  props: {
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    noDataText: { type: String, default: "No data available" },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object as PropType<DataOptions>,
      default: () => ({}),
    },
    serverItemsLength: { type: Number, default: -1 },
    enquiries: {
      type: Array as PropType<Enquiry[]>,
      default: () => [],
    },
  },
  methods: {
    numberOfRequestedItems(order: Enquiry): number {
      return order.requestedItems?.length ?? 0;
    },
    getTypes(types?: EnquiryType[] | null): string {
      if (types?.length) {
        return types
          .map((item: EnquiryType) => enquiryTypeLabelMapper(item))
          .join(",");
      }
      return "";
    },
    async dbClick(
      _: MouseEvent,
      {
        item,
      }: {
        expand: (value: boolean) => void;
        headers: DataTableHeader[];
        isExpanded: boolean;
        isMobile: boolean;
        isSelected: boolean;
        item: Enquiry;
        select: (value: boolean) => void;
      }
    ): Promise<void> {
      await this.$router.push({
        name: "Enquiry",
        params: { id: item.id ?? "" },
      });
    },
  },
});
