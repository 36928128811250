import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/dashboard/Home.vue";
import { getAuthInstance } from "@/services/auth/aadAuthService";
import { loginRequest } from "@/configs/authConfig";
import LogoutView from "@/views/auth/LogoutView.vue";
import LoginView from "@/views/auth/LoginView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/account",
    name: "Account",
    component: () =>
      import(
        /* webpackChunkName: "unauthorised" */ "../views/account/AccountView.vue"
      ),
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/unauthorised",
    name: "ErrorUnauthorised",
    component: () =>
      import(
        /* webpackChunkName: "unauthorised" */ "../views/error/ErrorViewUnauthorised.vue"
      ),
  },
  {
    path: "/users",
    name: "UsersDashboardView",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "../views/users/UsersDashboardView.vue"
      ),
  },
  {
    path: "/users/:id",
    name: "UserProfile",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "userProfile" */ "../views/users/UserProfileView.vue"
      ),
  },
  {
    path: "/webinars",
    name: "Webinars",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "webinars" */ "../views/webinars/WebinarsView.vue"
      ),
    redirect: { name: "WebinarsDashboard" },
    children: [
      {
        path: "/",
        name: "WebinarsDashboard",
        component: () =>
          import(
            /* webpackChunkName: "webinarsDashboard" */ "../views/webinars/WebinarsDashboardView.vue"
          ),
      },
      {
        path: "new",
        name: "CreateWebinar",
        component: () =>
          import(
            /* webpackChunkName: "webinarsCreate" */ "../views/webinars/WebinarCreate/WebinarCreateView.vue"
          ),
      },
      {
        path: ":id",
        name: "Webinar",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "webinar" */ "../views/webinars/WebinarView/WebinarView.vue"
          ),
      },
      {
        path: ":id/details",
        name: "WebinarEditDetails",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "webinarDetails" */ "../views/webinars/WebinarEdit/WebinarEditDetails.vue"
          ),
      },
      {
        path: ":id/speakers",
        name: "WebinarEditSpeakers",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "webinarSpeakers" */ "../views/webinars/WebinarEdit/WebinarEditSpeakersView.vue"
          ),
      },
      {
        path: ":id/agenda",
        name: "WebinarEditAgenda",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "webinarAgenda" */ "../views/webinars/WebinarEdit/WebinarEditAgendaView.vue"
          ),
      },
    ],
  },
  {
    path: "/enquiries",
    name: "Enquiries",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "webinars" */ "../views/enquiries/EnquiriesView.vue"
      ),
    redirect: { name: "EnquiriesDashboard" },
    children: [
      {
        path: "/",
        name: "EnquiriesDashboard",
        component: () =>
          import(
            /* webpackChunkName: "webinarsDashboard" */ "../views/enquiries/EnquiriesDashboardView.vue"
          ),
      },
      {
        path: ":id",
        name: "Enquiry",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "webinar" */ "../views/enquiries/EnquiryView.vue"
          ),
      },
      {
        path: "exports",
        name: "EnquiryExports",
        component: () =>
          import(
            /* webpackChunkName: "webinar" */ "../views/enquiries/EnquiriesDownloadsView.vue"
          ),
      },
    ],
  },
  {
    path: "/team",
    name: "Team",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "webinars" */ "../views/team/TeamView.vue"),
    redirect: { name: "TeamDashboard" },
    children: [
      {
        path: "/",
        name: "TeamDashboard",
        component: () =>
          import(
            /* webpackChunkName: "webinarsDashboard" */ "../views/team/TeamDashBoardView.vue"
          ),
      },
      {
        path: "/:id",
        name: "TeamMember",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "webinar" */ "../views/enquiries/EnquiryView.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (
    to.name === "Logout" ||
    to.name === "Login" ||
    to.name === "ErrorUnauthorised"
  ) {
    next();
    return;
  }

  const authService = getAuthInstance();

  if (!authService) {
    next({ name: "ErrorUnauthorised" });
    return;
  }

  await authService.isAuthenticated();
  if (!authService.authenticated) {
    await authService.loginRedirect(loginRequest);
    return;
  }

  if (authService.authenticated && authService.isAdmin) {
    next();
    return;
  }

  next("/unauthorised");
  return;
});
export default router;
