











































































import Vue from "vue";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import AppPage from "@/components/layout/AppPage.vue";
import AppUsersTable from "@/components/tables/AppUsersTable.vue";
import {
  AdminUserProfileResponse,
  ApiException,
  IWebinarDashboardAdminResponse,
  Enquiry,
  OrderBy,
  EnquiriesAdminClient,
  EnquirySortBy,
  EnquiryStatusType,
  UserRoleType,
  UsersAdminClient,
  UserSort,
  UserSummaryDto,
  WebinarAvailability,
  WebinarDashboardAdminResponse,
  WebinarsAdminClient,
  WebinarStatus,
} from "@/clients/clients";
import AppWebinarsTable from "@/components/tables/AppWebinarsTable.vue";
import { loginRequest } from "@/configs/authConfig";
import { mapGetters } from "vuex";
import AppEnquiriesTable from "@/components/tables/AppEnquiriesTable.vue";

export default Vue.extend({
  name: "Home",
  components: {
    AppEnquiriesTable,
    AppWebinarsTable,
    AppUsersTable,
    AppPage,
    AppPageBanner,
  },
  data: () => ({
    loading: false,
    errorMessages: [] as string[],
    usersAwaitingVerification: [] as UserSummaryDto[],
    newUsers: [] as UserSummaryDto[],
    webinars: [] as IWebinarDashboardAdminResponse[],
    enquiries: [] as Enquiry[],
  }),
  computed: {
    adminRoles(): typeof UserRoleType {
      return UserRoleType;
    },
    ...mapGetters(["account"]),
  },
  async created() {
    this.loading = true;
    this.errorMessages = [];
    try {
      const token = await this.$auth.acquireTokenSilent(loginRequest);
      const newUsersTask = this.getNewUsers(token.accessToken);
      const awaitingVerificationTask = this.getUnverifiedUsers(
        token.accessToken
      );
      const webinarsTask = this.getWebinars(token.accessToken);
      const getEnquiry = this.getNewEnquiries(token.accessToken);
      this.newUsers = await newUsersTask;
      this.usersAwaitingVerification = await awaitingVerificationTask;
      this.webinars = await webinarsTask;
      this.enquiries = await getEnquiry;
    } catch (e) {
      this.errorMessages.push((e as ApiException).message);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    canAccess(role: UserRoleType): boolean {
      return (
        (this.account as AdminUserProfileResponse).adminRoles?.some(
          (p) => p == UserRoleType.GlobalAdmin || p == role
        ) ?? false
      );
    },
    async getNewUsers(token: string): Promise<UserSummaryDto[]> {
      const userClient = this.$httpClientFactory(UsersAdminClient, token);
      const result = await userClient.getUsers(
        false,
        0,
        10,
        undefined,
        undefined,
        UserSort.BySignupDate,
        OrderBy.DESC
      );
      return result.users ?? [];
    },
    async getUnverifiedUsers(token: string): Promise<UserSummaryDto[]> {
      const userClient = this.$httpClientFactory(UsersAdminClient, token);
      const result = await userClient.getUsers(
        false,
        0,
        10,
        false,
        undefined,
        UserSort.BySignupDate,
        OrderBy.DESC
      );
      return result.users ?? [];
    },
    async getWebinars(token: string): Promise<WebinarDashboardAdminResponse[]> {
      const client = this.$httpClientFactory(WebinarsAdminClient, token);
      const result = await client.getWebinars(
        false,
        0,
        10,
        undefined,
        WebinarAvailability.Live,
        WebinarStatus.Published
      );
      return result.webinars ?? [];
    },
    async getNewEnquiries(token: string): Promise<Enquiry[]> {
      const client = this.$httpClientFactory(EnquiriesAdminClient, token);
      const result = await client.getEnquiries(
        false,
        0,
        10,
        [],
        EnquiryStatusType.Open,
        EnquirySortBy.ByEnquiryNumber,
        OrderBy.DESC
      );
      return result?.enquiries ?? [];
    },
  },
});
