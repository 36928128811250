




import Vue from "vue";

export default Vue.extend({
  name: "LogoutView",
  data: () => ({}),
  created() {
    setTimeout(() => {
      location.replace(process.env.VUE_APP_MARKETING_URL ?? location.href);
    }, 1000);
  },
});
