










import Vue from "vue";

export default Vue.extend({
  name: "SplashView",
  data: () => ({}),
  created() {
    if (this.$router.currentRoute.name === "Logout") {
      setTimeout(() => {
        location.replace(process.env.VUE_APP_MARKETING_URL ?? location.href);
      }, 1000);
    }
  },
});
