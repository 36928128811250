











































import Vue from "vue";
import TheNavigationDrawerMenu from "@/components/layout/TheNavigationDrawerMenu.vue";
import SplashView from "@/views/SplashView.vue";
import { mapGetters, mapMutations } from "vuex";
import { ApiException, UsersAdminClient } from "@/clients/clients";
import { loginRequest } from "@/configs/authConfig";

export default Vue.extend({
  name: "App",
  components: { SplashView, TheNavigationDrawerMenu },
  data: () => ({
    drawer: window.innerWidth >= 1264,
    mini: false,
    error: "",
  }),
  computed: {
    ...mapGetters(["account"]),
    wideScreen(): boolean {
      return window.innerWidth >= 1264;
    },
    loading(): boolean {
      return this.$store.state.appLoading;
    },
    authenticated(): boolean {
      return this.$auth.authenticated;
    },
    loginContinue(): boolean {
      return this.$router.currentRoute.name === "Login";
    },
    splash(): boolean {
      if (this.loginContinue) return false;
      return !this.authenticated;
    },
  },
  async created(): Promise<void> {
    if (this.loginContinue) return;
    this.setAppLoading(true);
    try {
      const token = await this.$auth.acquireTokenSilent(loginRequest);
      const client = this.$httpClientFactory(
        UsersAdminClient,
        token.accessToken
      );
      const account = await client.getAdminAccount();
      this.updateAccount(account);
    } catch (e) {
      this.error = (e as ApiException).message;
    } finally {
      this.setAppLoading(false);
    }
  },
  methods: {
    ...mapMutations(["updateAccount", "setAppLoading"]),
  },
});
