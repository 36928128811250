import Vue from "vue";
import Vuex from "vuex";
import WebinarUpsertStore from "@/store/WebinarUpsertStore";
import WebinarStore from "@/store/WebinarStore";
import { AdminUserProfileResponse } from "@/clients/clients";

Vue.use(Vuex);

interface IBaseState {
  appLoading: boolean;
  assetsBaseUrl: string;
  account: AdminUserProfileResponse | null;
}

const state: IBaseState = {
  appLoading: false,
  assetsBaseUrl: process.env.VUE_APP_ASSETS_BASE_URL,
  account: null,
};

export default new Vuex.Store({
  state,
  getters: {
    account: (state) => state.account,
  },
  mutations: {
    setAppLoading(state, payload) {
      state.appLoading = payload;
    },
    updateAccount(state, payload) {
      state.account = payload;
    },
  },
  actions: {},
  modules: {
    WebinarUpsert: WebinarUpsertStore,
    Webinar: WebinarStore,
  },
});
