import {
  BrandPromotion,
  WebinarAssociation,
  WebinarAvailability,
  WebinarCreateRequest,
  WebinarFullDetailsAdminResponse,
  WebinarStatus,
  WebinarUpdateDetailsRequest,
} from "@/clients/clients";

export default class WebinarDetailsFormModel {
  title = "";
  date = "";
  startTime = "";
  endTime = "";
  featured = false;
  description = "";
  textDescription = "";
  promoImage: File | null = null;
  certificateTemplateImage: File | null = null;
  imageChanged = false;
  onDemandVideoUri = "";
  availability: WebinarAvailability = WebinarAvailability.OnDemand;
  status: WebinarStatus = WebinarStatus.Draft;
  topic = "";
  continuingProfessionalDevelopment = false;
  cpdCredits = 0;
  tags: string[] = [];
  publish = false;
  associations: WebinarAssociation[] = [];
  newAssociations: {
    name: string;
    logo: File | null;
  }[] = [];
  brandPromotions: BrandPromotion[] = [];
  newBrandPromotions: {
    name: string;
    logo: File | null;
  }[] = [];

  jobCode: string | null= null;

  public init(
    data:
      | WebinarDetailsFormModel
      | WebinarCreateRequest
      | WebinarUpdateDetailsRequest
      | WebinarFullDetailsAdminResponse
  ): void {
    for (const [key, value] of Object.entries(data)){
      if (Object.prototype.hasOwnProperty.call(this, key)) {
        if (Array.isArray(value) && key === "tags") {
          this.tags = [...value];
          continue;
        }
        if (key === "status") {
          this.status = value;
          continue;
        }
        if (key === "availability") {
          this.availability = value;
          continue;
        }
        if (key === "publish") {
          this.publish = value;
          continue;
        }
        if (key === "date") {
          this.date = value.toISOString().split("T")[0];
          continue;
        }
        if (key === "newAssociations" || key === "newBrandPromotions") continue;
        this[key as keyof this] = value;
      }
    }
  }
}
