































import Vue, { PropType } from "vue";
import { IWebinarDashboardAdminResponse } from "@/clients/clients";
import { DataOptions } from "vuetify";

export default Vue.extend({
  name: "AppWebinarsTable",
  data: () => ({
    headers: [
      {
        text: "Title",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "Date", value: "date", align: "end" },
      { text: "Topic", value: "topic", align: "end" },
      { text: "Status", value: "status", align: "end" },
      { text: "Availability", value: "availability", align: "end" },
      { text: "Last updated", value: "updatedAt", align: "end" },
    ],
    tableOptions: {} as DataOptions,
  }),
  props: {
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    noDataText: { type: String, default: "No data available" },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object as PropType<DataOptions>,
      default: () => ({}),
    },
    serverItemsLength: { type: Number, default: -1 },
    webinars: {
      type: Array as PropType<IWebinarDashboardAdminResponse[]>,
      default: () => [],
    },
  },
  created() {
    this.tableOptions = Object.assign({}, this.tableOptions, this.options);
  },
  watch: {
    options: {
      handler() {
        this.tableOptions = Object.assign({}, this.tableOptions, this.options);
      },
      deep: true,
    },
  },
});
