import {
  BrandPromotionDto,
  ImageUploadDto,
  WebinarAssociationDto,
  WebinarCreateRequest,
  WebinarStatus,
  WebinarUpdateDetailsRequest,
} from "@/clients/clients";
import WebinarDetailsFormModel from "@/data/from/WebinarDetailsFormModel";

export async function buildWebinarCreateRequest(
  model: WebinarDetailsFormModel
): Promise<WebinarCreateRequest> {
  const request = new WebinarCreateRequest();
  if (model.promoImage !== null) {
    request.promotionalImage = await getImageUpload(model.promoImage);
  }

  if (model.certificateTemplateImage !== null) {
    request.certificateTemplate = await getImageUpload(
      model.certificateTemplateImage
    );
  }

  request.title = model.title;
  request.date = model.date
    ? new Date(`${model.date}T${model.startTime ?? "00:00"}:00Z`)
    : new Date();
  request.startTime = model.startTime;
  request.endTime = model.endTime;
  request.publish = model.publish;
  request.featured = model.featured;
  request.description = model.description;
  request.textDescription = model.textDescription;
  request.onDemandVideoUri = model.onDemandVideoUri;
  request.availability = model.availability;
  request.topic = model.topic;
  request.continuingProfessionalDevelopment =
    model.continuingProfessionalDevelopment ?? false;
  request.cpdCredits = model.cpdCredits ?? 0;
  request.tags = model.tags;
  request.jobCode = model.jobCode;
  request.newAssociations = await Promise.all(
    model.newAssociations
      .filter(
        (p: { name: string; logo: File | null }) =>
          p.name && p.name.length && p.logo
      )
      .map(
        async (item: {
          name: string;
          logo: File | null;
        }): Promise<WebinarAssociationDto> => {
          const val = new WebinarAssociationDto();
          val.name = item.name;
          if (item.logo) {
            val.logo = await getImageUpload(item.logo);
          }
          return val;
        }
      )
  );
  request.newBrandPromotions = await Promise.all(
    model.newBrandPromotions
      .filter((p) => p.name && p.name.length && p.logo)
      .map(async (item) => {
        const val = new BrandPromotionDto();
        val.name = item.name;
        if (item.logo) {
          val.logo = await getImageUpload(item.logo);
        }
        return val;
      })
  );
  return request;
}

export async function buildWebinarUpdateDetailsRequest(
  model: WebinarDetailsFormModel,
  id: string
): Promise<WebinarUpdateDetailsRequest> {
  const request = new WebinarUpdateDetailsRequest();
  request.id = id;
  if (model.promoImage !== null) {
    request.promotionalImage = await getImageUpload(model.promoImage);
  }

  if (model.certificateTemplateImage !== null) {
    request.certificateTemplate = await getImageUpload(
      model.certificateTemplateImage
    );
  }

  request.title = model.title;
  request.date = model.date
    ? new Date(`${model.date}T${model.startTime ?? "00:00"}:00Z`)
    : new Date();
  request.startTime = model.startTime;
  request.endTime = model.endTime;
  request.publish = model.status == WebinarStatus.Published;
  request.featured = model.featured;
  request.description = model.description;
  request.textDescription = model.textDescription;
  request.onDemandVideoUri = model.onDemandVideoUri;
  request.availability = model.availability;
  request.status = model.status;
  request.topic = model.topic;
  request.continuingProfessionalDevelopment =
    model.continuingProfessionalDevelopment ?? false;
  request.cpdCredits = model.cpdCredits ?? 0;
  request.tags = model.tags;
  request.jobCode = model.jobCode;
  request.associations = model.associations;
  request.newAssociations = await Promise.all(
    model.newAssociations
      .filter(
        (p: { name: string; logo: File | null }) =>
          p.name && p.name.length && p.logo
      )
      .map(
        async (item: {
          name: string;
          logo: File | null;
        }): Promise<WebinarAssociationDto> => {
          const val = new WebinarAssociationDto();
          val.name = item.name;
          if (item.logo) {
            val.logo = await getImageUpload(item.logo);
          }
          return val;
        }
      )
  );
  request.brandPromotions = model.brandPromotions;
  request.newBrandPromotions = await Promise.all(
    model.newBrandPromotions
      .filter((p) => p.name && p.name.length && p.logo)
      .map(async (item) => {
        const val = new BrandPromotionDto();
        val.name = item.name;
        if (item.logo) {
          val.logo = await getImageUpload(item.logo);
        }
        return val;
      })
  );
  return request;
}

async function getImageUpload(image: File): Promise<ImageUploadDto> {
  let promotionalImage = "";
  const arrayBuffer = await image?.arrayBuffer();
  const bytesArray = new Uint8Array(arrayBuffer).reduce(
    (data, byte) => data + String.fromCharCode(byte),
    ""
  );
  promotionalImage = btoa(bytesArray);
  const upload = new ImageUploadDto();
  upload.base64Image = promotionalImage;
  upload.fileName = image.name;
  return upload;
}
