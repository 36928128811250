var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.loading,"no-data-text":_vm.noDataText,"items":_vm.enquiries,"items-per-page":_vm.itemsPerPage,"options":_vm.tableOptions,"server-items-length":_vm.serverItemsLength,"hide-default-footer":_vm.hideDefaultFooter,"hide-default-header":_vm.hideDefaultHeader},on:{"update:options":[function($event){return _vm.$emit('update:options', $event)},function($event){_vm.tableOptions=$event}],"dblclick:row":_vm.dbClick},scopedSlots:_vm._u([{key:"item.numberOfItems",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberOfRequestedItems(item))+" ")]}},{key:"item.enquiryTypes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypes(item.enquiryTypes))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdAt.toLocaleDateString("en-GB"))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updatedAt.toLocaleDateString("en-GB"))+" ")]}},{key:"item.update",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{ name: 'Enquiry', params: { id: item.id } },"icon":""}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }