import { Configuration, LogLevel } from "@azure/msal-browser";

const b2cPolicies = {
  names: {
    signIn: "B2C_1A_SIGNUP_SIGNIN",
    passwordReset: "B2C_1A_PASSWORDRESET",
  },
  authorities: {
    signIn: {
      authority: process.env.VUE_APP_AZURE_B2C_SIGN_IN_AUTHORITY,
    },
    passwordReset: {
      authority: process.env.VUE_APP_AZURE_B2C_RESET_AUTHORITY,
    },
  },
  authorityDomain: process.env.VUE_APP_AZURE_B2C_KNOWN_AUTHORITIES,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.VUE_APP_AZURE_B2C_CLIENT_ID,
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: process.env.VUE_APP_AZURE_B2C_REDIRECT_URI,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const scopes = process.env.VUE_APP_AZURE_B2C_SCOPES.split(",").map(
  (scope: string) => `${process.env.VUE_APP_AZURE_B2C_APP_URI}/${scope}`
);
export const loginRequest = {
  scopes: scopes,
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
