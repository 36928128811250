import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import WebinarDetailsFormModel from "@/data/from/WebinarDetailsFormModel";
import {
  Agenda,
  IWebinarSettingsAdminResponse,
  SpeakerUpsertRequest,
  WebinarAvailability,
  WebinarCreateRequest,
  WebinarsAdminClient,
  WebinarUpdateAgendaRequest,
  WebinarUpdatedAdminResponse,
  WebinarUpdateSpeakersRequest,
} from "@/clients/clients";
import Vue from "vue";
import { getAuthInstance } from "@/services/auth/aadAuthService";
import { loginRequest } from "@/configs/authConfig";
import { httpClientFactory } from "@/services/http/http";
import { buildWebinarCreateRequest } from "@/Utils/buildWebinarCreateRequest";

interface IWebinarUpsertStoreState {
  settings: IWebinarSettingsAdminResponse;
  id: string | null;
  details: WebinarDetailsFormModel;
  speakers: SpeakerUpsertRequest[];
  agenda: Agenda[];
  webinarAvailability: WebinarAvailability;
}

const state: IWebinarUpsertStoreState = {
  settings: {
    speakers: [],
    tags: [],
  },
  id: null,
  details: new WebinarDetailsFormModel(),
  speakers: [],
  agenda: [],
  webinarAvailability: WebinarAvailability.OnDemand,
};
const mutations: MutationTree<IWebinarUpsertStoreState> = {
  updateId(state: IWebinarUpsertStoreState, payload: string): void {
    state.id = payload;
  },
  updateSettings(
    state: IWebinarUpsertStoreState,
    payload: IWebinarSettingsAdminResponse
  ): void {
    state.settings = Object.assign({}, state.settings, payload);
  },
  updateDetails(
    state: IWebinarUpsertStoreState,
    payload: WebinarDetailsFormModel
  ): void {
    state.details = Object.assign({}, state.details, payload);
    state.webinarAvailability = state.details.availability;
  },
  deleteDetails(state: IWebinarUpsertStoreState): void {
    state.details = new WebinarDetailsFormModel();
  },
  upsertSpeaker(
    state: IWebinarUpsertStoreState,
    payload: SpeakerUpsertRequest
  ): void {
    const index = state.speakers.findIndex(
      (p) => p.fullName == payload.fullName && p.email == payload.email
    );
    const speaker = new SpeakerUpsertRequest(payload);
    if (!state.speakers.length || index < 0) {
      state.speakers.push(speaker);
      return;
    }
    Vue.set(state.speakers, index, speaker);
  },
  removeSpeaker(
    state: IWebinarUpsertStoreState,
    payload: SpeakerUpsertRequest
  ): void {
    const index = state.speakers.findIndex(
      (p) => p.fullName == payload.fullName && p.email == payload.email
    );
    if (!state.speakers.length || index < 0) return;
    state.speakers.splice(index, 1);
  },
  setSpeakers(
    state: IWebinarUpsertStoreState,
    payload: SpeakerUpsertRequest[]
  ): void {
    state.speakers = [];
    state.speakers = [...payload].map((item) => new SpeakerUpsertRequest(item));
  },
  upsertAgendaItem(state: IWebinarUpsertStoreState, payload: Agenda): void {
    const index = state.agenda.findIndex(
      (p) => p.item == payload.item && p.speaker == payload.speaker
    );
    if (!state.agenda.length || index < 0) {
      state.agenda.push(payload);
      return;
    }
    Vue.set(state.agenda, index, payload);
  },
  removeAgendaItem(state: IWebinarUpsertStoreState, payload: Agenda): void {
    const index = state.agenda.findIndex(
      (p) => p.item == payload.item && p.speaker == payload.speaker
    );
    if (!state.agenda.length || index < 0) return;
    state.agenda.splice(index, 1);
  },
  setAgenda(state: IWebinarUpsertStoreState, payload: Agenda[]): void {
    state.agenda = [];
    state.agenda = [...payload].map((item) => new Agenda(item));
  },
  reset(state: IWebinarUpsertStoreState): void {
    state.agenda = [];
    state.id = "";
    state.details = new WebinarDetailsFormModel();
    state.speakers = [];
    state.agenda = [];
  },
};

const actions: ActionTree<IWebinarUpsertStoreState, any> = {
  async getSettings({ commit }): Promise<IWebinarSettingsAdminResponse> {
    const auth = getAuthInstance();
    const tokenRequest = await auth?.authClient.acquireTokenSilent(
      loginRequest
    );
    const accessToken = tokenRequest?.accessToken;
    const client = httpClientFactory(WebinarsAdminClient, accessToken);
    const settings = await client.getSettings();
    commit("updateSettings", settings);
    return settings;
  },
  async createWebinar({
    state,
    commit,
  }): Promise<WebinarUpdatedAdminResponse | void> {
    const auth = getAuthInstance();
    const tokenRequest = await auth?.authClient.acquireTokenSilent(
      loginRequest
    );
    const accessToken = tokenRequest?.accessToken;
    const client = httpClientFactory(WebinarsAdminClient, accessToken);
    const webinarCreateRequest: WebinarCreateRequest =
      await buildWebinarCreateRequest(state.details);
    try {
      let webinarResponse: WebinarUpdatedAdminResponse =
        await client.createWebinar(webinarCreateRequest);
      commit("updateId", webinarResponse.id ?? "");
      const id = state.id ?? "";
      if (state.speakers.length) {
        webinarResponse = await client.updateWebinarSpeakers(
          id,
          new WebinarUpdateSpeakersRequest({
            id: id,
            speakers: state.speakers,
          })
        );
      }
      if (state.agenda.length) {
        webinarResponse = await client.updateWebinarAgenda(
          id,
          new WebinarUpdateAgendaRequest({
            id: id,
            agendas: state.agenda,
          })
        );
      }
      commit("reset");
      return webinarResponse;
    } catch (e) {
      commit("reset");
    }
  },
};

const getters: GetterTree<IWebinarUpsertStoreState, any> = {
  settings: (state: IWebinarUpsertStoreState): IWebinarSettingsAdminResponse =>
    state.settings,
  speakers: (state: IWebinarUpsertStoreState): SpeakerUpsertRequest[] =>
    state.speakers,
  availability: (state: IWebinarUpsertStoreState): WebinarAvailability =>
    state.webinarAvailability,
};

const WebinarUpsertStore: Module<IWebinarUpsertStoreState, any> = {
  namespaced: true,
  state: () => state,
  mutations,
  actions,
  getters,
};

export default WebinarUpsertStore;
