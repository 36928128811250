




























import Vue, { PropType } from "vue";
import { UserSummaryDto } from "@/clients/clients";
import { DataOptions } from "vuetify";

export default Vue.extend({
  name: "AppUsersTable",
  data: () => ({
    headers: [
      { text: "First name", value: "firstName" },
      { text: "Last name", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Verified", value: "verified" },
      { text: "Blocked", value: "blocked" },
      { text: "Signed up", value: "createdAt" },
      { text: "Last updated", value: "updatedAt" },
      { text: "Update", value: "update", sortable: false },
    ],
    tableOptions: {} as DataOptions,
  }),
  props: {
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    noDataText: { type: String, default: "No data available" },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object as PropType<DataOptions>,
      default: () => ({}),
    },
    serverItemsLength: { type: Number, default: -1 },
    users: {
      type: Array as PropType<UserSummaryDto[]>,
      default: () => [],
    },
  },
});
