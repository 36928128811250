import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { AadPlugin } from "@/plugins/aadPlugin";
import { msalConfig } from "@/configs/authConfig";
import { AxiosPlugin } from "@/plugins/axios";

import "@/styles/styles.scss";

Vue.config.productionTip = false;

Vue.use(AadPlugin, { router: router, msalConfig: msalConfig });
Vue.use(AxiosPlugin);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
